
.span_center {
    display: flex;
	align-content: flex-start;
	gap: 0.3rem;
}

.span_center_electro {
	display: flex;
	align-content: flex-start;
	flex-wrap: wrap;
	gap: 0.3rem;
}

.phone_call a {
	color: black;
	margin: auto;
	display: flex;
	flex-direction: column;
}


.lep {
    width: 900px;
}

.lep_img {
    width: 100%;
    height: 200px;
    object-fit: cover;
	object-position: 100% 85%;
}

.listening {
	margin-bottom: 1.5rem;
}

