* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Noto Sans', sans-serif;
}

:root {
	--mainColor: #29335c;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #eee;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background-color: var(--mainColor);
    color: var(--textColor);
}

nav h3,
a {
    margin: 0 1rem;
    color: var(--textColor);
    text-decoration: none;
}

.logo {
	margin-left: 3rem;
	width: 350px;
}

nav h3,
a:hover {
    color: var(--secondaryColor);
}

header .nav_btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
	display: flex;
}

nav {
    display: flex;
    align-items: center;
	justify-content: flex-end;
	width: 1000px;
	gap: 2rem;
}

.table li {
	text-decoration: none;
}

.map_2gis {
	display: block;
	margin-top: 1rem;
}

.map_2gis_mobile {
	display: none;
	margin: 0.5rem 0 0.5rem 0;
}

.location_on_phone {
	display: none;
}
